import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES, PATHS } from '../../util/constants';
import {
  setMenuCategories,
  setCurrentCategoryInfo,
  setCurrentDishInfo,
  setSpecialOffers,
  setCurrentSpecialOffer,
} from '../menus/menus';
import {
  setIsDataLoading,
  setIsLoadingFailed,
  setPageIsLoading,
} from '../apiConnection/apiConnection';
import { setMessage } from './view';

export const getMenuCategories = () => {
  return (dispatch) =>
    axios
      .get('menus/', { headers: tokenHeader() })
      .then((response) => {
        dispatch(setMenuCategories(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
};

export const getCurrentCategoryInfo = (categoryId) => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    return axios
      .get(`menus/${categoryId}`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCurrentCategoryInfo(response.data));
      })
      .catch((error) => {
        if (error.response?.data?.detail === 'Не найдено.') {
          dispatch(
            setMessage({
              messageStatus: MESSAGE_TYPES.WARNING,
              message:
                'Выбранная категория недоступна, вы можете заказать блюда из другой категории',
              timeOutToClose: 5000,
              redirectPath: PATHS.index,
              btnDesk: 'перейти к списку меню',
            }),
          );
        } else {
          dispatch(setIsLoadingFailed(true));
          dispatch(
            setMessage({
              messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };
};

export const getCurrentDishInfo = (dishId) => {
  return (dispatch) => {
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    return axios
      .get(`dishes/${dishId}/`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCurrentDishInfo(response.data));
      })
      .catch((error) => {
        dispatch(setIsLoadingFailed(true));
        if (error.response?.data?.detail === 'Не найдено.') {
          dispatch(
            setMessage({
              messageStatus: MESSAGE_TYPES.WARNING,
              message:
                'Выбранная категория недоступна, вы можете заказать блюда из другой категории',
              timeOutToClose: 5000,
              redirectPath: PATHS.index,
              btnDesk: 'перейти к списку меню',
            }),
          );
        } else {
          dispatch(
            setMessage({
              messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
};

export function getSpecialOffers() {
  return (dispatch) => {
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    axios
      .get('special_offers/', { headers: tokenHeader() })
      .then((response) => {
        dispatch(setSpecialOffers(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
            message:
              'Извините, информация об акциях в данный момент недоступна!',
          }),
        );
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
}

export const getCurrentSpecialOffer = (offerId, goToSpecialOffers) => {
  return (dispatch) => {
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    axios
      .get(`special_offers/${offerId}/`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCurrentSpecialOffer(response.data));
      })
      .catch(() => {
        goToSpecialOffers();
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
};
