import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES, MODAL_TYPES, PATHS } from '../../util/constants';
import { deleteCookie } from '../../util/cookies';
import { checkNeedUserData } from '../../util/checkUserData';
import {
  logOut as logOutSuccess,
  setCurrentUserInfo,
  setCurrentUserPresence,
} from '../currentUser/currentUser';
import { clearOrders } from '../orders/orders';
import { clearModal, setModalContent } from '../view/view';
import { setMessage } from './view';
import {
  setIsDataLoading,
  setIsLoadingFailed,
} from '../apiConnection/apiConnection';
import { setNeedReloadUserData } from '../currentUser/currentUser';
import { setNeedReloadDishInfo } from '../menus/menus';
import { setIsNeedReloadCartDishes } from '../userCart/userCart';

export const logOut = () => {
  return (dispatch) =>
    axios
      .get('client/current/logout/', { headers: tokenHeader() })
      .then(() => dispatch(logOutSuccess()))
      .then(() => dispatch(clearOrders()));
};

export const getCurrentUserInfo = (onAuth, history) => {
  return (dispatch) =>
    axios
      .get('client/current/', { headers: tokenHeader() })
      .then(({ data }) => {
        dispatch(setCurrentUserInfo(data));
        if (onAuth) {
          dispatch(clearModal());
        }
        const needUserData = checkNeedUserData(data);
        if (!needUserData && !data.presence_state) {
          dispatch(
            setModalContent({
              modalType: MODAL_TYPES.PRESENCE_INFO,
              dontNeedCloseBtn: true,
              dontNeedCloseByEsc: true,
              classNameOfModalContainer: 'presence-info-modal',
            }),
          );
        } else if (needUserData) {
          history.push(PATHS.profile);
          dispatch(
            setMessage({
              additionalMessage: 'Заполните, пожалуйста, пустые поля профиля',
            }),
          );
        }
        return data;
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
            message: 'Извините, не удалось получить информацию о пользователе!',
          }),
        );
      })
      .finally(() => {
        dispatch(setNeedReloadUserData(false));
      });
};

export const patchUserData = (values, setSubmitting, currentPath) => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    axios
      .patch('client/current/', values, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCurrentUserInfo(response.data));
        if (!response.data.presence_state) {
          dispatch(
            setModalContent({
              modalType: MODAL_TYPES.PRESENCE_INFO,
              dontNeedCloseBtn: true,
              dontNeedCloseByEsc: true,
              classNameOfModalContainer: 'presence-info-modal',
              isShowSuccessProfileEdit: true,
            }),
          );
          return;
        }
        dispatch(clearModal());
        showSuccessProfileEdit(dispatch, currentPath, null);
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(clearModal());
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.ERROR,
            message: 'Извините, не удалось обновить данные!',
            timeOutToClose: 5000,
          }),
        );
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
        setSubmitting(false);
      });
  };
};

export const patchPresenceInfo = (
  values,
  setSubmitting,
  currentPath,
  isShowSuccessProfileEdit,
) => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    axios
      .patch('client/current/', values, { headers: tokenHeader() })
      .then((response) => {
        if ('takeaway_restaurant' in values) {
          deleteCookie('savedRestaurant');
        } else {
          deleteCookie('savedAddress');
        }
        deleteCookie('savedPresence');
        dispatch(setCurrentUserInfo(response.data));
        dispatch(clearModal());
        dispatch(setNeedReloadDishInfo(true));
        dispatch(setIsNeedReloadCartDishes(true));
        if ('takeaway_restaurant' in values) {
          if (response.data.cooking_restaurant_warning) {
            dispatch(
              setMessage({
                messageStatus: MESSAGE_TYPES.WARNING,
                message: response.data.cooking_restaurant_warning,
                isShowSuccessProfileEdit,
              }),
            );
            return;
          }
        }
        showSuccessProfileEditAfterPresence(
          isShowSuccessProfileEdit,
          dispatch,
          currentPath,
        );
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(clearModal());
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
            message: 'Извините, не удалось обновить данные!',
            timeOutToClose: 5000,
          }),
        );
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
        setSubmitting(false);
      });
  };
};

export const setCurrentUserPresenceMethod = (value) => {
  return (dispatch) => {
    dispatch(setCurrentUserPresence(value));
  };
};

const showSuccessProfileEditAfterPresence = (
  isShowSuccessProfileEdit,
  dispatch,
  currentPath,
) => {
  if (isShowSuccessProfileEdit) {
    showSuccessProfileEdit(dispatch, currentPath);
  }
};

const showSuccessProfileEdit = (
  dispatch,
  currentPath,
  redirectPath = PATHS.index,
) => {
  if (currentPath === PATHS.profile) {
    dispatch(
      setMessage({
        messageStatus: MESSAGE_TYPES.SUCCESS_PROFILE_EDIT,
        redirectPath,
      }),
    );
  }
};
