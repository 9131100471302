import './DeliveryAddressSelector.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { getDeliveryStatusDebounced } from '../../util/api';
import {
  DEFAULT_ADDRESS,
  DEFAULT_CLIENT_COORDINATE,
  DEFAULT_DELIVERY_STATUS,
  MODAL_TYPES,
} from '../../util/constants';
import { setCookie, getCookie } from '../../util/cookies';
import PropTypes from 'prop-types';
import { addressFromCookiePresent } from '../../util/misc';
import * as _ from 'lodash';
import { removeMinusBefore } from '../../util/validators';
import { formatHouse } from '../../components/StreetAutocomplete/StreetAutocomplete';
import { selectRestaurant } from '../../store/selectors/selectRestaurant';
import { DeliveryStatus } from '../DeliveryStatus';

export function DeliveryAddressSelectorWithoutMap({
  setSelectedAddress,
  deliveryStatus,
  setDeliveryStatus,
  requiredFieldsTouched,
  isAnyAddressPossible,
  className,
}) {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const [clientCoordinate, setClientCoordinate] = useState(
    DEFAULT_CLIENT_COORDINATE,
  );
  const initialAddress = addressFromCookiePresent(getCookie('savedAddress'))
    ? getCookie('savedAddress')
    : userInfo?.address || DEFAULT_ADDRESS;
  const [address, setAddress] = useState(initialAddress);
  const [isStreetTouched, setIsStreetTouched] = useState(false);
  const [isHouseTouched, setIsHouseTouched] = useState(false);
  const deliveryContacts = useSelector((state) =>
    selectRestaurant(state, deliveryStatus?.restaurant),
  );

  const shortAddress = formatHouse(address);

  useEffect(() => {
    if (!_.isEqual(userInfo?.address, address) && userInfo?.address) {
      setAddress(userInfo?.address);
    }
  }, [userInfo?.address]);

  useEffect(() => {
    getDeliveryStatusDebounced.cancel();
    if (!address.street || !address.house) {
      dispatch(actionCreators.setDeliveryTimes([]));
      clearClientCoordinate();
      clearDeliveryStatus();
      return;
    }
    const data = {
      address: {
        street: address?.street,
        house: address?.house,
        corp: address?.corp,
        ...(address?.city && { city: address.city }),
        ...(address?.settlement && { settlement: address.settlement }),
      },
    };
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
    dispatch(actionCreators.setMinPriceForFreeDelivery(null));
    getDeliveryStatusDebounced(
      data,
      setDeliveryStatus,
      setClientCoordinate,
      dispatch,
    );
  }, [
    address.street,
    address.house,
    address.corp,
    address.city,
    address.settlement,
  ]);

  useEffect(() => {
    if (deliveryStatus.isPositive) {
      setSelectedAddress(address);
    }
  }, [deliveryStatus, isStreetTouched, isHouseTouched]);

  useEffect(() => {
    setIsStreetTouched(requiredFieldsTouched);
    setIsHouseTouched(requiredFieldsTouched);
  }, [requiredFieldsTouched]);

  useEffect(() => {
    if (isAnyAddressPossible) {
      setSelectedAddress(address);
    }
  }, [address]);

  const onChangeAddress = (value, key) => {
    if (address[key] === value) {
      return;
    }
    const inputs = ['entrance', 'flat'];
    const newAddress = {
      ...address,
      [key]: inputs.includes(key) ? removeMinusBefore(value) : value,
    };
    setCookie('savedAddress', newAddress, 1);
    setAddress(newAddress);
  };

  const clearDeliveryStatus = () => {
    if (!deliveryStatus.message && !deliveryStatus.isPositive) {
      return;
    }
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
  };

  const clearClientCoordinate = () => {
    if (clientCoordinate.lat === null && clientCoordinate.lon === null) {
      return;
    }
    setClientCoordinate(DEFAULT_CLIENT_COORDINATE);
  };

  const showPrecenceInfoModal = (e) => {
    e.preventDefault();

    dispatch(
      actionCreators.setModalContent({
        modalType: MODAL_TYPES.PRESENCE_INFO,
        placeOrder: true,
        dontNeedCloseBtn: true,
        classNameOfModalContainer: 'presence-info-modal',
      }),
    );
  };

  const handleChange = ({ target: { value, name } }) => {
    onChangeAddress(value, name);
  };

  return (
    <div className={`place-order__delivery-address ${className}`}>
      <div className="place-order__map-title">Адрес доставки</div>
      <div className="place-order__adress text-field">
        <input
          placeholder="Улица"
          className="text-field__input"
          id="street-autocomplete"
          value={shortAddress}
          readOnly
        />
        <div className="error-map-div">
          {deliveryStatus.message && (
            <DeliveryStatus
              deliveryStatus={deliveryStatus}
              deliveryContacts={deliveryContacts}
            />
          )}
        </div>
        <button
          className="edit-button edit-button_place-order"
          onClick={showPrecenceInfoModal}
        >
          <span className="icon-edit-address" />
        </button>
      </div>
      <div className="place-order__address-wrapper">
        <input
          className="text-field__input text-field__input--xs"
          placeholder="Парадная"
          type="number"
          maxLength="6"
          value={address.entrance}
          name="entrance"
          onChange={handleChange}
        />

        <input
          className="text-field__input text-field__input--xs"
          placeholder="Этаж"
          type="number"
          name="floor"
          maxLength="6"
          value={address.floor}
          onChange={handleChange}
        />

        <input
          className="text-field__input text-field__input--xs"
          placeholder="Кв./Офис"
          type="number"
          name="flat"
          maxLength="6"
          value={address.flat}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

DeliveryAddressSelectorWithoutMap.propTypes = {
  setSelectedAddress: PropTypes.func.isRequired,
  deliveryStatus: PropTypes.object.isRequired,
  setDeliveryStatus: PropTypes.func.isRequired,
  requiredFieldsTouched: PropTypes.bool,
  isAnyAddressPossible: PropTypes.bool,
  className: PropTypes.string,
};
