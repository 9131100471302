import { Link as LinkMui } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './DeliveryStatus.scss';

export function DeliveryStatus({
  deliveryStatus,
  deliveryContacts,
  onNavigation,
}) {
  return (
    <div
      className={clsx(
        'delivery-status',
        deliveryStatus.isPositive && 'delivery-status--success',
        !deliveryStatus.isPositive && 'delivery-status--error',
      )}
    >
      {deliveryStatus.message}
      {deliveryContacts && (
        <>
          . Заказ приедет из ресторана{' '}
          <LinkMui
            color="secondary"
            underline="always"
            component={Link}
            to={`/рестораны/${deliveryContacts.id}`}
            onClick={onNavigation}
          >
            «{deliveryContacts.name}»
          </LinkMui>
          <span className="delivery-status--free-delivery">
            . Бесплатная доставка от{' '}
            {deliveryStatus.min_price_for_free_delivery}₽.
          </span>
        </>
      )}
    </div>
  );
}

DeliveryStatus.propTypes = {
  deliveryStatus: PropTypes.object.isRequired,
  deliveryContacts: PropTypes.object,
  onNavigation: PropTypes.func,
};
