import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuCategories: [],
  currentCategoryInfo: null,
  currentDishInfo: null,
  specialOffers: null,
  currentSpecialOffer: null,
  needReloadDishInfo: false,
};

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenuCategories: (state, { payload }) => {
      state.menuCategories = payload;
    },
    setCurrentCategoryInfo: (state, { payload }) => {
      state.currentCategoryInfo = payload;
      state.needReloadDishInfo = false;
    },
    setCurrentDishInfo: (state, { payload }) => {
      state.currentDishInfo = payload;
      state.needReloadDishInfo = false;
    },
    setSpecialOffers: (state, { payload }) => {
      state.specialOffers = payload;
    },
    setCurrentSpecialOffer: (state, { payload }) => {
      state.currentSpecialOffer = payload;
    },
    setNeedReloadDishInfo: (state, { payload }) => {
      state.needReloadDishInfo = payload;
    },
  },
});

export const {
  setMenuCategories,
  setCurrentCategoryInfo,
  setCurrentDishInfo,
  setSpecialOffers,
  setCurrentSpecialOffer,
  setNeedReloadDishInfo,
} = menusSlice.actions;

export default menusSlice.reducer;
