import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES } from '../../util/constants';
import { setUserCart, setFriendCart } from '../userCart/userCart';
import { setMessage } from './view';
import { setIsLoadingFailed } from '../apiConnection/apiConnection';
import { setIsNeedReloadCartDishes } from '../userCart/userCart';

export const getUserCart = () => {
  return (dispatch) =>
    axios
      .get(`basket_dishes/`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setUserCart(response.data));
        return response.data;
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(dispatch(setIsNeedReloadCartDishes(false)));
};

export const getFriendCart = (link) => {
  return (dispatch) => {
    axios
      .get(`basket/${link}/`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setFriendCart(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const addFriendCartToCurrent = (link, history) => {
  return (dispatch) => {
    axios
      .patch(`basket/${link}/add_shared/`, {}, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setUserCart(response.data));
        history.push('/корзина');
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const addToUserCart = (dish, data) => {
  return (dispatch) => {
    axios
      .post(`dishes/${dish.id}/add_to_basket/`, data, {
        headers: tokenHeader(),
      })
      .then((response) => {
        dispatch(setUserCart(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const deleteFromCart = (id) => {
  return (dispatch) => {
    axios
      .delete(`basket_dishes/${id}/`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setUserCart(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const changeDishAmount = (dish, cart, count) => {
  return (dispatch) => {
    axios
      .patch(
        `basket_dishes/${dish.id}/`,
        { quantity: dish.quantity + count },
        { headers: tokenHeader() },
      )
      .then((response) => {
        dispatch(setUserCart(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const basketShare = (cart, setLink) => {
  return (dispatch) => {
    axios
      .post(`basket/create_shared/`, cart, { headers: tokenHeader() })
      .then((response) => {
        setLink(response.data.pretty_link);
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};
