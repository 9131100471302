import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import YandexMap from '../../components/YandexMap/YandexMap';
import * as actionCreators from '../../store/actions';
import './DeliveryCheck.scss';
import GeoButton from '../../components/GeoButton/GeoButton';
import StreetAutocomplete from '../../components/StreetAutocomplete/StreetAutocomplete';
import { getClientAddress, getDeliveryStatusDebounced } from '../../util/api';
import { DEFAULT_ADDRESS } from '../../util/constants';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';
import { selectRestaurant } from '../../store/selectors/selectRestaurant';
import { DeliveryStatus } from '../../reusable/DeliveryStatus';
import { DEFAULT_DELIVERY_STATUS } from '../../util/constants';

const DEFAULT_CLIENT_COORDINATE = {
  lat: null,
  lon: null,
};

const DeliveryCheck = () => {
  useTitleUpdate('Проверка возможности доставки');
  useBreadcrumbs(
    'inner-container inner-container--sm',
    '/условия доставки/проверка возможности доставки',
    '/условия-доставки',
  );
  const restaurantsInfo = useSelector(
    (state) => state.restaurants.restaurantsInfo,
  );
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const dispatch = useDispatch();
  const [address, setAddress] = useState(DEFAULT_ADDRESS);
  const [deliveryStatus, setDeliveryStatus] = useState(DEFAULT_DELIVERY_STATUS);
  const [clientCoordinate, setClientCoordinate] = useState(
    DEFAULT_CLIENT_COORDINATE,
  );
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const restaurantsInfoPresent = !!restaurantsInfo.length;
  const [formErrors, setFormErrors] = useState({
    street: null,
    house: null,
    geo: null,
  });
  const deliveryContacts = useSelector((state) =>
    selectRestaurant(state, deliveryStatus?.restaurant),
  );

  useEffect(() => {
    getDeliveryStatusDebounced.cancel();
    if (!address.street || !address.house) {
      clearClientCoordinate();
      clearDeliveryStatus();
      return;
    }
    const data = {
      address: {
        street: address?.street,
        house: address?.house,
        corp: address?.corp,
        ...(address?.city && { city: address.city }),
        ...(address?.settlement && { settlement: address.settlement }),
      },
    };
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
    getDeliveryStatusDebounced(
      data,
      setDeliveryStatus,
      setClientCoordinate,
      dispatch,
    );
  }, [
    address.street,
    address.house,
    address.corp,
    address.city,
    address.settlement,
  ]);

  useEffect(() => {
    if (!restaurantsInfoPresent && !isLoadingFailed) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [restaurantsInfoPresent, isLoadingFailed, dispatch]);

  const onChangeAddress = (obj) => {
    setAddress((prev) => ({ ...prev, ...obj }));
  };

  const clearClientCoordinate = () => {
    if (clientCoordinate.lat === null && clientCoordinate.lon === null) {
      return;
    }
    setClientCoordinate(DEFAULT_CLIENT_COORDINATE);
  };

  const clearDeliveryStatus = () => {
    if (!deliveryStatus.message && !deliveryStatus.isPositive) {
      return;
    }
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
  };

  const setGeoUser = (coordinates) => {
    clearClientCoordinate();
    clearDeliveryStatus();
    dispatch(
      getClientAddress(
        coordinates,
        setAddress,
        setDeliveryStatus,
        setFormErrors,
      ),
    );
  };

  const handleGeolocationError = (message) => {
    setDeliveryStatus({ message, isPositive: false });
  };

  if (!companyInfo) {
    return null;
  }

  return (
    <div className="inner-container inner-container--sm">
      <div className="delivery-check-areas">
        <div className="delivery-areas__title">
          Проверьте возможность доставки <br />
          по вашему адресу
        </div>
        <div className="error-map-div error-map-div_address-check">
          {deliveryStatus.message && (
            <DeliveryStatus
              deliveryStatus={deliveryStatus}
              deliveryContacts={deliveryContacts}
            />
          )}
        </div>
        <div className="delivery-check-areas__map">
          <YandexMap
            restaurants={restaurantsInfo}
            clientCoordinate={clientCoordinate}
          />
        </div>

        <Formik
          initialValues={{
            house: address.house || '',
            corp: address.corp || '',
          }}
          enableReinitialize={true}
        >
          {() => (
            <Form className="delivery-areas__form">
              <div className="delivery-areas__full-address">
                <GeoButton
                  setGeoUser={setGeoUser}
                  onError={handleGeolocationError}
                />
                <StreetAutocomplete
                  onChangeStreet={(obj) => {
                    const { street, house } = obj;

                    const streetError = !street ? 'Введите улицу' : '';
                    const houseError = !house ? 'Введите номер дома' : '';

                    setFormErrors((prev) => ({
                      ...prev,
                      street: streetError,
                      house: houseError,
                      ...(!!prev?.geo && { geo: null }),
                    }));

                    onChangeAddress(obj);
                  }}
                  address={address}
                  containerClass={clsx(
                    'delivery-areas__street',
                    (!!formErrors.street ||
                      !!formErrors.house ||
                      !!formErrors.geo) &&
                      'text-field--error',
                  )}
                  errors={formErrors}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DeliveryCheck;
