import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES } from '../../util/constants';
import { setCurrentRestaurantInfo } from '../restaurants/restaurants';
import {
  setPageIsLoading,
  setIsLoadingFailed,
} from '../apiConnection/apiConnection';
import { setMessage } from './view';

export const getRestaurant = (id, restaurantsInfo) => {
  return (dispatch) => {
    restaurantsInfo = restaurantsInfo || [];
    const currentRestaurantInfo = restaurantsInfo.find((r) => r.id === id);
    if (currentRestaurantInfo) {
      dispatch(setCurrentRestaurantInfo(currentRestaurantInfo));
      return;
    }
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    return axios
      .get(`restaurants/${id}`, { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCurrentRestaurantInfo(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
};
