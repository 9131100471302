import { useSelector } from 'react-redux';
import { SPECIAL_ROLES } from '../../util/constants';
import './DontPress.scss';

export default function DontPress() {
  const userInfo = useSelector((state) => state.currentUser.userInfo);

  const touch = () => {
    throw Error('User pressed "don\'t press" button');
  };

  return (
    <div className="dangerous-container">
      {userInfo?.phone === SPECIAL_ROLES.callCenterPhone && (
        <button onClick={touch} className="dangerous-button">
          Don't press
        </button>
      )}
    </div>
  );
}
