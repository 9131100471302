import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { deleteCookie } from '../../util/cookies';
import { MESSAGE_TYPES, ORDER_COOKIES, PATHS } from '../../util/constants';
import {
  setInitialOrders,
  addOrders,
  setDeliveryInfo,
  promocodeAdded,
  setDiscountAmount,
  setSurveyQuestions,
} from '../orders/orders';
import { setMessage } from './view';
import {
  setIsDataLoading,
  setIsLoadingFailed,
  setPageIsLoading,
} from '../apiConnection/apiConnection';
import { setRestorantPickupTime, setDeliveryTimes } from '../orders/orders';
import { getUserCart } from './userCart';
import { setCompanyContacts } from '../companyInfo/companyInfo';
import { setUserCart } from '../userCart/userCart';

export const getRestaurantDeliveryTimes = (idRestaurant) => (dispatch) =>
  axios
    .get(`restaurants/${idRestaurant}/takeaway_times/`, {
      headers: tokenHeader(),
    })
    .then((response) => {
      dispatch(setRestorantPickupTime(response.data.takeaway_times));
      dispatch(setDeliveryTimes(response.data.takeaway_times));
    })
    .catch(() => {
      dispatch(setIsLoadingFailed(true));
      dispatch(
        setMessage({
          messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
        }),
      );
    });

export const getInitialOrders = () => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    return axios
      .get('orders/', { headers: tokenHeader() })
      .then((response) => {
        const nextPage = response.data.next
          ? response.data.next.split('?page=')[1]
          : null;
        dispatch(
          setInitialOrders({ orders: response.data.results, next: nextPage }),
        );
        return response.data.results;
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };
};

export const fetchNextOrders = (pageNumber) => {
  return (dispatch) => {
    return axios
      .get(`orders/?page=${pageNumber}`, { headers: tokenHeader() })
      .then((response) => {
        const nextPage = response.data.next
          ? response.data.next.split('?page=')[1]
          : null;
        dispatch(addOrders({ orders: response.data.results, next: nextPage }));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const repeatOrder = (orderId) => {
  return (dispatch) => {
    return axios
      .post(`orders/${orderId}/repeat_order/`, {}, { headers: tokenHeader() })
      .then(() => {
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.OK,
            message: 'Блюда добавлены в корзину',
          }),
        );
        dispatch(getUserCart());
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const getPayment = (orderId) => {
  return (dispatch) => {
    return axios
      .get(`orders/${orderId}/`, { headers: tokenHeader() })
      .then((response) => {
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        }
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const getDeliveryInfo = (areRestaurantsPresent) => {
  return (dispatch) => {
    const requests = [
      axios.get('client/current/delivery_info/', { headers: tokenHeader() }),
    ];
    !areRestaurantsPresent && requests.push(axios.get('restaurants/'));
    return Promise.all(requests)
      .then((responseArr) => {
        dispatch(setDeliveryInfo(responseArr[0].data));
        !areRestaurantsPresent &&
          dispatch(setCompanyContacts(responseArr[1].data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
  };
};

export const checkPromoCode = (promocode, presence) => {
  return (dispatch) => {
    return axios
      .post(
        'client/check_promo_code/',
        {
          presence,
          promo_code: promocode,
        },
        { headers: tokenHeader() },
      )
      .then((response) => {
        if (response.data.discount) {
          dispatch(promocodeAdded(response.data.discount || 0));
        } else if (response.data.discount_amount) {
          dispatch(setDiscountAmount(response.data.discount_amount || 0));
        }
      })
      .catch((error) => {
        let message = 'Неверный промокод';
        const errorData = error.response?.data;
        if (
          errorData &&
          Array.isArray(errorData['non_field_errors']) &&
          errorData['non_field_errors'][0]
        ) {
          message = errorData['non_field_errors'][0];
        }
        dispatch(
          setMessage({
            message,
            messageStatus: MESSAGE_TYPES.ERROR,
          }),
        );
      });
  };
};

export const postOrder = (data, history, setOrdersNumberToLocalStorage) => {
  return (dispatch) => {
    return axios
      .post('orders/', data, { headers: tokenHeader() })
      .then((response) => {
        setOrdersNumberToLocalStorage(response.data.number);
        dispatch(setUserCart([]));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.OK,
            message: 'Ваш заказ принят',
          }),
        );
        Object.values(ORDER_COOKIES).forEach((cookie) => deleteCookie(cookie));
        history.push('/заказы');
        if (
          response.data?.payment_type !== 'cash' &&
          response.data?.payment_type !== 'cashless'
        ) {
          window.location.href = response.data.payment_url;
          return;
        }
      })
      .catch((error) => {
        const field = Object.keys(error.response?.data)[0];
        let message = error.response?.data[field];
        if (typeof error.response?.data[field] === 'object') {
          message = error.response?.data[field][0];
        }
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.ERROR,
            message,
            redirectPath: PATHS.orders,
          }),
        );
      });
  };
};

export function getSurveyQuestions() {
  return (dispatch) => {
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    axios
      .get('survey_questions/', { headers: tokenHeader() })
      .then((response) => {
        dispatch(setSurveyQuestions(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
}

export const sendSurveyAnswers = (data) => {
  return (dispatch) => {
    dispatch(setPageIsLoading({ pageIsLoading: true }));
    axios
      .post('survey_response/', data, { headers: tokenHeader() })
      .then(() => {
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.OK,
            message: 'Спасибо за отзыв',
            timeOutToClose: 2000,
            redirectPath: PATHS.index,
          }),
        );
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageIsLoading({ pageIsLoading: false }));
      });
  };
};
