import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { useParams } from 'react-router-dom';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DishDescription from '../../reusable/DishGrid/DishDescription/DishDescription';
import * as actionCreators from '../../store/actions';
import { changeStyle, buttonClassName } from '../../util/dishButtonAnimation';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import defaultSrc from '../../assets/img/no-dish.png';
import Flier from '../../reusable/Flier';
import {
  CART_ICON_ID,
  MESSAGE_TYPES,
  MOBILE_ANIMATION_TARGET,
  MODAL_TYPES,
} from '../../util/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { Divider } from '../../reusable/Divider/Divider';
import { DishPageIcons } from './DishPageIcons';

import './DishPage.scss';
import { getRelateddishPrice } from './helpers/getRelatedDishPrice';

const emptyFlierProps = {
  animate: false,
  from: { x: null, y: null },
  to: { x: null, y: null },
};

function DishPage() {
  const { page, dish } = useParams();

  const dishId = dish.split('-')[0];

  const dispatch = useDispatch();

  const history = useHistory();

  const loggedIn = useSelector((state) => state.currentUser.isAuthorized);
  const currentDishInfo = useSelector((state) => state.menus.currentDishInfo);
  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);

  const [flierProps, setFlierProps] = useState(emptyFlierProps);
  const orderBtnRef = useRef(null);

  const [groupValues, setGroupValues] = useState({});

  const [currentToppings, setCurrentToppings] = useState([]);

  const [currId, setCurrId] = useState(null);

  const [animateStyle, setAnimateStyle] = useState('card-button__onclick');

  const infoPresent = !!currentDishInfo;
  const hash = decodeURIComponent(history.location).hash;

  SwiperCore.use([Navigation]);

  const isSameDishId = dishId === currentDishInfo?.id.toString();

  function getUrlGroups() {
    if (Object.keys(groupValues).length) {
      const groupsArray = Object.entries(groupValues).map((group) => {
        return group;
      });
      return `#parts:${groupsArray.join('/')}`;
    }
    return '#';
  }

  function getUrlToppings() {
    if (currentToppings.length) {
      const toppingsArray = currentToppings.map((topping) => {
        return Object.values(topping);
      });
      return `#tpngs:${toppingsArray.join('/')}`;
    }
    return '#';
  }

  function makeNewLocalGroupsInfo() {
    if (hash && hash.includes('parts')) {
      const groupsFromHash =
        hash.indexOf('parts') === 1 && hash.split('#')[1].slice(6);
      const hashGroups = {};
      for (let group of groupsFromHash.split('/')) {
        const groupId = group.split(',')[0];
        hashGroups[groupId] = group.slice(+groupId.length + 1);
      }
      return hashGroups;
    }
    return;
  }

  function makeNewLocalToppingsInfo() {
    if (hash && hash.includes('tpngs')) {
      const toppingsFromHash =
        hash.includes('tpngs') && hash.slice(hash.indexOf('tpngs') + 6);
      let toppings = [];
      for (let topping of toppingsFromHash.split('/')) {
        const toppingInfo = topping.split(',');
        toppings.push({
          id: +toppingInfo[0],
          price: +toppingInfo[1],
          count: +toppingInfo[2],
          totalPrice: +toppingInfo[3],
          dishId: +toppingInfo[4],
        });
      }
      return toppings;
    }
    return;
  }

  const groupsInfoFromHash = makeNewLocalGroupsInfo();
  const toppingsInfoFromHash = makeNewLocalToppingsInfo();

  const menuCategories = useSelector((state) => state.menus.menuCategories);

  const getMenuCategory = (id, dish, responseType) => {
    if (!id || !menuCategories?.length) return '';

    const menuCategoryId = dish.category.menu.id;

    return menuCategories.find(({ id }) => id === menuCategoryId)[responseType];
  };

  useBreadcrumbs(
    'inner-container inner-container--xs product-card--big',
    `меню/${getMenuCategory(currentDishInfo?.category?.menu?.id, currentDishInfo, 'name')}/${
      infoPresent && currentDishInfo.name
    }`,
    `/${page}`,
  );

  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const needReloadDishInfo = useSelector(
    (state) => state.menus.needReloadDishInfo,
  );

  useEffect(() => {
    if (
      (!currentDishInfo ||
        currentDishInfo.id !== +dishId ||
        needReloadDishInfo) &&
      !isLoadingFailed
    ) {
      dispatch(actionCreators.getCurrentDishInfo(dishId));
    }
  }, [currentDishInfo, dishId, isLoadingFailed, needReloadDishInfo, dispatch]);

  useEffect(() => {
    if (isSameDishId) {
      document.title = `На Парах | ${currentDishInfo.name}`;
    }
  }, [currentDishInfo?.name, isSameDishId]);

  useEffect(() => {
    if (!currentDishInfo || currentDishInfo.id !== +dishId) {
      return;
    }

    if (!!currentToppings.length || !!Object.keys(groupValues).length) {
      history.push(
        `/${page}/${currentDishInfo.id}-${currentDishInfo.slug}${getUrlGroups()}${getUrlToppings()}`,
      );
    }
  }, [groupValues, currentToppings]);

  useEffect(() => {
    if (!infoPresent) {
      return;
    }

    if (groupsInfoFromHash && !!Object.keys(groupsInfoFromHash).length) {
      setGroupValues(groupsInfoFromHash);
    }
    if (toppingsInfoFromHash && !!toppingsInfoFromHash.length) {
      setCurrentToppings(toppingsInfoFromHash);
    }

    if (!Object.keys(groupValues).length) {
      if (groupsInfoFromHash && !!Object.keys(groupsInfoFromHash).length) {
        setGroupValues(groupsInfoFromHash);
      } else {
        const initialValues = {};
        for (let group of currentDishInfo.groups) {
          initialValues[group.id] = '';
        }
        setGroupValues(initialValues);
      }
    }

    if (!currentToppings.length) {
      if (toppingsInfoFromHash && !!toppingsInfoFromHash.length) {
        setCurrentToppings(toppingsInfoFromHash);
      } else {
        setCurrentToppings(getToppings());
      }
    }
  }, [infoPresent]);

  const dishIcons = useMemo(
    () => [
      currentDishInfo?.gluten_level,
      currentDishInfo?.spice,
      currentDishInfo?.lactose_level,
      currentDishInfo?.vegiterian,
      currentDishInfo?.sugar_presence,
    ],
    [currentDishInfo],
  );

  if (!currentDishInfo) {
    return null;
  }

  const handleGroupValueChange = (ev, id) => {
    const currentValue = { [id]: ev.target.value };
    setGroupValues({ ...groupValues, ...currentValue });
  };

  function groupsTriggerModal() {
    for (let key in groupValues) {
      if (!groupValues[key]) {
        const currentGroup = currentDishInfo.groups.find(
          (group) => group.id === +key,
        );
        return currentGroup.name;
      }
    }
  }

  function getToppings() {
    if (!currentDishInfo.toppings.length) {
      return [];
    }

    return currentDishInfo.toppings.map((topping) => ({
      id: topping.id,
      price: Number.parseInt(topping.price),
      count: 0,
      totalPrice: 0,
      dishId: +dishId,
    }));
  }

  function onSuggestedDishClick(dish) {
    if (loggedIn) {
      if (dish.pos_type === 'constructor') {
        const categoryId = dish.category.menu.id;
        const categorySlug = getMenuCategory(categoryId, dish, 'slug');
        history.push(`/${categoryId}-${categorySlug}/${dish.id}-${dish.slug}`);
      } else {
        dispatch(actionCreators.addToUserCart(dish));
        changeStyle(setAnimateStyle);
      }
    } else {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          btnType: 'addToBasket',
        }),
      );
    }
  }

  function toppingCount(id) {
    if (!!currentToppings.length) {
      const toppings = currentToppings;
      let currentTopping = toppings.find((topping) => topping.id === id);
      return currentTopping.count;
    }
  }

  function totalPrice() {
    const toppings = currentToppings;
    return (
      currentDishInfo.price +
      Object.values(groupValues).reduce(
        (prev, current) => prev + (+current.split(',')[0] || 0),
        0,
      ) +
      toppings.reduce((prev, topping) => prev + topping.totalPrice, 0)
    );
  }

  const calculatedTotalPrice = totalPrice();

  function partLabel(part, group, field) {
    const price = Number.parseInt(part.price);

    if (field === 'value') {
      return `${price},${group.id},${part.id},${+dishId}`;
    } else if (field === 'label') {
      return !!price ? `${part.name} ${price} ₽` : part.name;
    }
  }

  function changeToppingsCount(action, id) {
    if (!!currentToppings.length) {
      setCurrentToppings((currentToppings) =>
        currentToppings.map((topping) => {
          if (topping.id === id) {
            if (action === 'plus') {
              topping.count += 1;
            } else if (action === 'minus' && topping.count > 0) {
              topping.count -= 1;
            }

            topping.totalPrice = topping.count * topping.price;
          }
          return topping;
        }),
      );
    }
  }

  function toppingsForCart() {
    const toppingsArr = [];
    currentToppings.forEach((topping) => {
      if (!!topping.count)
        toppingsArr.push({
          id: topping.id,
          quantity: topping.count,
        });
    });
    return toppingsArr;
  }

  function partsForCart() {
    return Object.values(groupValues).map((group) => {
      return {
        id: +group.split(',')[2],
      };
    });
  }

  function onAddToCartClick(dish) {
    if (!loggedIn) {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          btnType: 'addToBasket',
        }),
      );
    } else {
      const data = {
        parts: partsForCart(),
        toppings: toppingsForCart(),
      };
      const skippedGroup = groupsTriggerModal();
      if (!!currentDishInfo.groups.length && !!skippedGroup) {
        dispatch(
          actionCreators.setMessage({
            messageStatus: MESSAGE_TYPES.WARNING,
            message: `необходимо выбрать ${skippedGroup}`,
          }),
        );
      } else {
        const animationTarget = screenIsLarge
          ? CART_ICON_ID
          : MOBILE_ANIMATION_TARGET;
        const cartIconElem = document.getElementById(animationTarget);
        if (orderBtnRef.current && cartIconElem) {
          const {
            y: fromY,
            x: fromX,
            width: fromWidth,
          } = orderBtnRef.current.getBoundingClientRect();
          const { y: toY, x: toX } = cartIconElem.getBoundingClientRect();

          setFlierProps({
            from: { x: fromX, y: fromY, width: fromWidth },
            to: { x: toX, y: toY, width: 10 },
            animate: true,
          });
        }

        dish.pos_type === 'constructor'
          ? dispatch(actionCreators.addToUserCart(dish, data))
          : dispatch(actionCreators.addToUserCart(dish));
      }
    }
  }

  const isUnavailable =
    currentDishInfo.is_on_stop || !currentDishInfo.is_available_by_time;

  const onRelatedDishClick = (dish) => {
    const categoryId = dish.category.menu.id;
    const categorySlug = getMenuCategory(categoryId, dish, 'slug');

    const path = `/${categoryId}-${categorySlug}/${dish.id}-${dish.slug}`;

    history.push(path);
  };

  return (
    isSameDishId && (
      <div className="inner-container inner-container--xs product-card--big">
        <Card className={clsx('dish_card', { 'on-stop': isUnavailable })}>
          <DishPageIcons icons={dishIcons} />
          {currentDishInfo.images.length > 1 ? (
            <Swiper slidesPerView={1} loop={true} navigation>
              {currentDishInfo.images.map((src, index) => {
                return (
                  <SwiperSlide key={index}>
                    <CardMedia
                      style={{
                        backgroundImage: `url(${src}), url(${defaultSrc})`,
                      }}
                      className="with-default-bgi"
                      component="div"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <CardMedia
              style={{
                backgroundImage: `url(${currentDishInfo.images[0]}), url(${defaultSrc})`,
              }}
              className="with-default-bgi"
              component="div"
            />
          )}
          <CardContent>
            <DishDescription
              dish={currentDishInfo}
              description={true}
              currentToppings={currentToppings}
              groupValues={groupValues}
              dishCalculate={true}
            />
            {!!currentDishInfo.groups.length && (
              <div>
                <Divider className="constructor__divider" />
                {currentDishInfo.groups.map((group) => {
                  return (
                    <div key={group.id}>
                      <div className="constructor__radio radio">
                        <h3 className="radio__title">{group.name}</h3>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label={group.name}
                            name={group.name}
                            value={groupValues[group.id] || null}
                            onChange={(ev) => {
                              handleGroupValueChange(ev, group.id);
                            }}
                          >
                            {group.parts.map((part) => (
                              <FormControlLabel
                                key={part.id}
                                style={{ width: '100%' }}
                                value={partLabel(part, group, 'value')}
                                control={<Radio />}
                                label={partLabel(part, group, 'label')}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <Divider className="constructor__divider" />
                    </div>
                  );
                })}
              </div>
            )}
            {!!currentDishInfo.toppings.length && (
              <div>
                {currentDishInfo.toppings.map((topping) => (
                  <div className="constructor__food-switcher" key={topping.id}>
                    <div className="food-switcher__title">{topping.name}</div>
                    <div className="food-switcher__content">
                      <div className="food-switcher__price">
                        {!!toppingCount(topping.id)
                          ? toppingCount(topping.id) *
                            Number.parseInt(topping.price)
                          : Number.parseInt(topping.price)}
                        <span style={{ fontSize: '1rem' }}> ₽</span>
                      </div>
                      <div className="amount-switcher">
                        <Fab
                          color="primary"
                          aria-label="delete"
                          style={{ boxShadow: 'none' }}
                          onClick={() => {
                            changeToppingsCount('minus', topping.id);
                          }}
                        >
                          <span className="Fab-content__minus" />
                        </Fab>
                        <span className="amount-switcher__value">
                          {toppingCount(topping.id) || 0}
                        </span>
                        <Fab
                          color="primary"
                          aria-label="add"
                          style={{ boxShadow: 'none' }}
                          onClick={() => {
                            changeToppingsCount('plus', topping.id);
                          }}
                        >
                          <span className="Fab-content__plus" />
                        </Fab>
                      </div>
                    </div>
                  </div>
                ))}
                <Divider className="constructor__divider" />
              </div>
            )}
            {!!currentDishInfo.price &&
              calculatedTotalPrice !== currentDishInfo.price && (
                <div className="constructor-price">
                  Цена без добавок:
                  <span className="constructor-price__big">
                    {' ' + currentDishInfo.price}
                    <span className="rub-sign"> &#8381;</span>
                  </span>
                </div>
              )}
            <div className="price-button-wrapper">
              <div className="price-button-wrapper__price">
                {!!calculatedTotalPrice && (
                  <>
                    <span className="price-button-wrapper__title">Цена:</span>
                    <span className="price-button-wrapper__value">
                      {calculatedTotalPrice}
                      <span className="rub-sign"> ₽</span>
                    </span>
                  </>
                )}
              </div>
              <button
                className="button-standard price-button-wrapper__dish-button"
                onClick={() => {
                  onAddToCartClick(currentDishInfo);
                }}
                ref={orderBtnRef}
              >
                Заказать
              </button>
              {flierProps.animate && (
                <Flier
                  {...flierProps}
                  durationMs={800}
                  withOpacity
                  withWidth
                  onAnimationEnd={() => setFlierProps(emptyFlierProps)}
                >
                  <div className="button-standard flying-button">Заказать</div>
                </Flier>
              )}
            </div>
            {currentDishInfo.note && (
              <p className="product-card__note note">
                <span className="note__desc">{currentDishInfo.note}</span>
                <span className="note__icon">*</span>
              </p>
            )}
          </CardContent>
        </Card>
        {!!currentDishInfo.bought_together.length && (
          <div className="related-products">
            <div className="related-products__title">С этим заказывают:</div>
            <div className="related-products__list">
              {currentDishInfo.bought_together.map((dish) => (
                <Card key={dish.id} onClick={() => onRelatedDishClick(dish)}>
                  <CardMedia
                    style={{
                      backgroundImage: `url(${dish.images[0]}), url(${defaultSrc})`,
                    }}
                    className="with-default-bgi"
                    component="div"
                  />
                  <CardContent>
                    <div className="related-products__item-title">
                      {dish.name}
                    </div>
                    <div className="product-card__content-bottom">
                      {dish?.price && (
                        <div className="product-card__price">
                          {getRelateddishPrice(dish)}
                          <span className="rub-sign"> &#8381;</span>
                        </div>
                      )}
                      <button
                        className="product-card__button button-secondary dish-page__related-dish-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrId(dish.id);
                          onSuggestedDishClick(dish);
                        }}
                      >
                        <div
                          className={buttonClassName(
                            currId,
                            dish.id,
                            animateStyle,
                          )}
                          id={dish.id}
                        />
                        <span className="product-card__button-plus">+</span>
                        <span className="icon-product-card__button-">
                          <span className="icon-cart" />
                        </span>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default DishPage;
