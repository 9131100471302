import { createSlice } from '@reduxjs/toolkit';
import { getScreenIsLarge, getSidebarAlwaysOpened } from '../../util/misc';

const initialState = {
  screenIsLarge: getScreenIsLarge(window.innerWidth),
  isSidebarAlwaysOpened: getSidebarAlwaysOpened(window.innerWidth),
  modalContent: null,
  breadcrumbs: null,
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setScreenIsLarge(state, { payload }) {
      state.screenIsLarge = payload;
    },
    setSidebarAlwaysOpened(state, { payload }) {
      state.isSidebarAlwaysOpened = payload;
    },
    setModalContent(state, { payload }) {
      state.modalContent = payload;
    },
    clearModal(state) {
      state.modalContent = null;
    },
    setBreadcrumbs(state, { payload }) {
      state.breadcrumbs = payload;
    },
    clearBreadcrumbs(state) {
      state.breadcrumbs = null;
    },
  },
});

export const {
  setScreenIsLarge,
  setSidebarAlwaysOpened,
  setModalContent,
  clearModal,
  setBreadcrumbs,
  clearBreadcrumbs,
} = viewSlice.actions;

export default viewSlice.reducer;
